.Backstage{
    &--Left{
        float:left;
        width: calc(50%);
        margin: 0px 12px 12px 12px;
        height: calc(100vh - 150px);
    }
    &--Right{
        float: left;
        width: calc(50% - 32px);
        margin: 0px 0px 12px 0px;
        height: calc(100vh - 150px);
    }
}

.Backstage--Plugin--Telepronter{
    float: left;
    width: 100%;
    height: 64px;
    margin-bottom: 0px !important;
}

.Backstage--Plugin--Chat{
    .Plugin--Chat{
        height: 100%;
    }
    float: left;
    width: 100%;
    height: calc(100% - 382px);
    display: flex;
    flex-direction: column;
    margin-bottom: 0px !important;
    .list{
        height: calc(100% - 54px);
        overflow-y: scroll;
        .header{
            color: rgba(255,255,255,0.75) !important;
        }
        .description{
            color: rgba(255,255,255,0.6) !important;
        }
    }   
}

.Backstage--Tools{
    height: 96px;
    .ui.compact.menu{
        display: flex !important;
        a{
            flex-basis: 25%;
        }
    }
}

.Backstage--Participants{
    height: calc(100% + 10px - 110px);
    overflow-y: scroll;
}

input {
    background: transparent !important;
    border: 1px solid rgba(255,255,255,0.25) !important;
    color: rgba(255,255,255,0.75) !important;
    & + i{
        color: rgba(255,255,255,0.75) !important;
    }
    &:hover,
    &:active{
        color: rgba(255,255,255,0.95) !important;
    }
}