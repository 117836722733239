.Login{
  & > .ui.center.aligned.middle.aligned.grid{
    height: 100vh;
    .isMobile & {
      height: 90vh;
      .column{
        margin: 0 24px;
      }
    }
  }
  h2{
    color: white !important;
    .sub.header{
      color: rgba(255,255,255,0.65) !important;
    }
  }
  .list{
    text-align: left !important;
  }
  color: rgba(255,255,255,0.65) !important;

  .ui.message,
  .ui.stacked.segment {
    color: #FFF;
    background: rgba(0,0,0,0.69);
  }
  .ui.stacked.segment{
    min-height: 120px;
    .list{
      i.icon.large.middle.aligned{
        min-width: 48px;
      }
      .item{
        &.disabled{
          opacity: 0.33;
        }
      }
    }
  }

  form.ui.large.form{
    input{
      background: transparent;
      border: 1px solid rgba(255,255,255,0.25);
      color: #FFF;
    }
    i.icon{
      color: #FFFFFF !important;
    }
  }
}

:not(input){
  // user-select: none;
}

#root{
  overflow-y: hidden;
  overflow-x: hidden;
}

.pusher{
  // background: #00000000 !important;
}

.ui.segment.pushable{
  margin-top: -5px;
  background :none !important;
  overflow-y: hidden;
  .ui.wide.right.sidebar{
    background: #000;
    margin-top: 12px !important;
    height: calc(100% - 24px) !important;
    margin-right: 12px !important;

    font-size: 120%;
    * {
      text-align: left;
      color: #FFF !important;
    }
    .item{
      h4{
        margin-top: 0px;
      }
      p{
        margin-top: -10px;
        color: #FFFFFF90 !important;
        font-size: 90%;
      }
      &.disabled{
        color: #FFFFFF50 !important;
      }
    }
  }
  & > .pusher {
    min-height: 99%;
  }
}