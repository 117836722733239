.Plugin--Mosaic{
    img{
        width: 250px;
        max-height: 250px;
    }
    & > div{
        float: left;
        margin-right: 12px;
        max-width: 240px;
        max-height: 200px;
        video{
            width: 100%;
        }
    }
}

.Mosaic--Close{
    position: fixed;
    top: 24px;
    right: 24px;
    z-index: 100000;
}

.ui.active.transition.visible.page.dimmer{
    overflow-y: scroll;
}